<!-- @format -->

<template>
  <div
    class="flex flex-col items-center justify-center p-6 space-y-4 rounded-lg"
  >
    <SignaturePad
      ref="signaturePad"
      :width="500"
      :height="300"
      :customStyle="customStyle"
    ></SignaturePad>
    <div class="flex w-full gap-2 py-4">
      <label class="w-32">Enter Name: </label>
      <input
        class="border-teal border-b-2 border-t-0 border-l-0 border-r-0 w-full focus:outline-none"
        v-model="signeeName"
        placeholder="Enter name"
      />
    </div>
    <div v-if="nameError" class="text-red-500">{{ nameError }}</div>
    <div v-if="signatureError" class="text-red-500">{{ signatureError }}</div>
    <div class="flex flex-row-reverse gap-x-4 w-full justify-center">
      <button
        @click="saveSignature"
        class="py-2 w-1/3 text-white bg-teal hover:bg-teal hover:bg-opacity-80 rounded-lg font-medium"
      >
        Add
      </button>

      <button
        @click="clearSignature"
        class="py-2 w-1/3 border-2 border-teal text-teal rounded-lg font-medium"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";
import SignaturePad from "vue3-signature-pad";

const emit = defineEmits(["at-save-signature"]);
const props = defineProps({
  signee: {
    type: String,
    default: "",
  },
  isEdited: {
    type: Boolean,
  },
});

const signaturePad = ref(null);
const signeeName = ref("");
const customStyle = { border: "3px solid teal", margin: "auto" };
const signatureImageUrl = ref("");
const signatureDataUrl = ref("");
const singatureImage = ref("");
const nameError = ref("");
const signatureError = ref("");

watch(
  () => props.signee,
  (newVal) => {
    if (props.isEdited) {
      if (newVal) {
        signeeName.value = newVal;
      }
    }
  },
  { immediate: true }
);

const saveSignature = () => {
  nameError.value = "";
  signatureError.value = "";

  const nameLength = signeeName.value.trim().length;
  if (nameLength < 3) {
    nameError.value = "Name must be at least 3 characters.";
  } else if (nameLength > 20) {
    nameError.value = "Name must not exceed 20 characters.";
  }

  if (!signeeName.value) {
    nameError.value = "Name is required.";
  }

  if (signaturePad.value) {
    signatureDataUrl.value = signaturePad.value.saveSignature("image/png");
    if (!signatureDataUrl.value) {
      signatureError.value = "Signature is required.";
    }
  }

  if (nameError.value || signatureError.value) {
    return;
  }

  const signatureBlob = convertBase64ToImage(signatureDataUrl.value.data);

  singatureImage.value = new File(
    [signatureBlob],
    `${signeeName.value}_signature.png`,
    {
      type: "image/png",
    }
  );
  signatureImageUrl.value = URL.createObjectURL(signatureBlob);
  emit("at-save-signature", {
    singatureImage: signatureImageUrl.value,
    signee: signeeName.value,
    signatureImageFile: singatureImage.value,
  });
};

const convertBase64ToImage = (base64) => {
  const base64Data = base64.split(",")[1];
  const mimeString = base64.split(",")[0].match(/:(.*?);/)[1];

  const byteString = atob(base64Data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const clearSignature = () => {
  if (signaturePad.value) {
    signaturePad.value.clearSignature();
    signatureDataUrl.value = "";
    signeeName.value = "";
    nameError.value = "";
    signatureError.value = "";
  }
};
</script>
