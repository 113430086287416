<!-- @format -->

<template>
  <div class="flex justify-center">
    <div class="bg-white shadow-md w-full rounded-md">
      <div v-if="isPreview" class="flex justify-between">
        <img src="../../../assets/images/border_left.png" alt="image-1" />
        <img src="../../../assets/images/border_right.png" alt="image-2" />
      </div>
      <div class="px-10 py-6">
        <div
          class="flex justify-between items-center border-b-4 border-teal pb-4"
        >
          <h1 class="text-black text-3xl font-normal">
            Medical Letter
            <span class="font-semibold">{{
              medicalLetter?.medical_letter?.name
            }}</span>
          </h1>
          <img
            src="https://via.placeholder.com/150x60.png?text=RUSHDEN+MEDICAL+CENTRE"
            alt="Rushden Medical Centre"
            class="h-16"
          />
        </div>

        <div class="font-bold text-gray-800 mt-8 mb-4">Certification</div>
        <table class="w-full text-left mb-5">
          <tr>
            <th class="w-1/3 text-black font-light text-sm">
              Healthcare Provider:
            </th>
            <td class="text-teal text-sm">Rushden Health Centre</td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">GP Service:</th>
            <td class="text-teal text-sm">COC Registered</td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Validity:</th>
            <td class="text-teal text-sm">1 Year</td>
          </tr>
        </table>

        <div class="font-bold text-gray-800 mt-8 mb-4">
          Personal Information
        </div>
        <table class="w-full text-left mb-5">
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Name:</th>
            <td class="text-teal text-sm">
              {{ medicalLetter?.first_name + " " + medicalLetter?.last_name }}
            </td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Sex:</th>
            <td class="text-teal text-sm capitalize">
              {{ medicalLetter?.gender }}
            </td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Date of Birth:</th>
            <td class="text-teal text-sm">
              {{ moment(medicalLetter?.dob)?.format("DD/MM/YYYY") }}
            </td>
          </tr>
        </table>

        <table class="w-full text-left mb-5">
          <tr>
            <th class="w-1/3 text-black font-light text-sm">
              Type of Allergy:
            </th>
            <td class="text-teal text-sm">
              <input
                type="text"
                class="border border-b border-l-0 border-r-0 border-t-0 focus:outline-none"
                v-model="typeOfAllergy"
                @input="emitFieldChange('typeOfAllergy', typeOfAllergy)"
                placeholder="Enter type of allergy"
                v-if="!isPreview"
              />
              <p v-else>{{ typeOfAllergy }}</p>
            </td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Allergens:</th>
            <td class="text-teal text-sm">
              <input
                type="text"
                class="border border-b border-l-0 border-r-0 border-t-0 focus:outline-none"
                v-model="allergens"
                @input="emitFieldChange('allergens', allergens)"
                placeholder="Enter allergens"
                v-if="!isPreview"
              />
              <p v-else>{{ allergens }}</p>
            </td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Date Submitted:</th>
            <td class="text-teal text-sm">06/08/1998</td>
          </tr>
          <tr>
            <th class="w-1/3 text-black font-light text-sm">Date Validated:</th>
            <td class="text-teal text-sm">06/08/1998</td>
          </tr>
        </table>

        <div class="text-gray-800 leading-7 text-sm">
          <p>To Whom It May Concern:</p>
          <textarea
            class="border w-3/4 rounded-lg p-4 text-sm h-32"
            placeholder="Write Your Message....."
            v-model="letterBody"
            @input="emitFieldChange('letterBody', letterBody)"
            v-if="!isPreview"
          ></textarea>
          <p v-else>{{ letterBody }}</p>
          <div v-if="!isPreview">
            <p class="">
              Prescribed Medication: <span v-if="!isPreview">(Optional)</span>
            </p>
            <div class="flex gap-2">
              <input
                v-model="newMedication"
                type="text"
                class="p-2 border rounded-lg"
                placeholder="Enter medication"
              />
              <button
                @click="addMedication"
                class="text-teal text-md font-semibold"
              >
                +Add
              </button>
            </div>
          </div>

          <ol class="text-teal mt-2 grid grid-cols-2">
            <li
              v-for="(medication, index) in medications"
              :key="index"
              class="font-medium flex flex-wrap items-center gap-2"
            >
              {{ index + 1 }}. {{ medication }}
              <span
                class="material-icons w-3 text-black text-sm cursor-pointer"
                @click="removeMedication(index)"
                v-if="!isPreview"
                >close</span
              >
            </li>
          </ol>

          <p>
            Should you require further information, please do not hesitate to
            contact my office.
          </p>
          <p>Thank you for your attention to this matter.</p>
        </div>

        <div class="flex justify-between items-center mt-4">
          <div
            v-if="isSignatureLoading"
            class="flex items-center justify-center w-1/6 h-24"
          >
            <div
              class="animate-spin rounded-full h-10 w-8 border-t-4 border-teal border-b-4"
            ></div>
          </div>
          <div class="w-1/6 text-center h-full" v-else>
            <img
              :src="
                signature?.signature_image
                  ? signature?.signature_image
                  : signature?.singatureImage
                  ? signature?.singatureImage
                  : 'https://via.placeholder.com/150x60.png?text=ADD+YOUR+SIGNATURE'
              "
              class="w-full"
            />
            <p class="border-t-2 border-black pt-1 capitalize">
              {{
                signature?.signature_name
                  ? signature?.signature_name
                  : signature?.signee
                  ? signature?.signee
                  : "Signee Name"
              }}
            </p>
          </div>
          <div class="text-center h-8">
            <p class="mb-1">{{ moment(date)?.format("DD MMMM YYYY") }}</p>
            <p class="border-t-2 border-black pt-1 font-bold">Date</p>
          </div>
        </div>
      </div>

      <div
        class="text-center text-sm font-light text-white border-t border-teal bg-teal p-5"
        v-if="isPreview"
      >
        Rushden Health, Adnitt Road, NN10 9TR<br />
        <a href="mailto:info@rushden.com" class="text-white no-underline"
          >info@rushden.com</a
        >
        |
        <a href="http://www.rushden.com" class="text-white no-underline"
          >www.rushden.com</a
        >
        | +44 (0) 987 3456
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, defineEmits } from "vue";
import { defineProps } from "vue";
import moment from "moment";
const props = defineProps({
  isPreview: {
    type: Boolean,
  },
  signature: {
    type: String,
  },
  medicalLetter: {
    type: Object,
  },
  isSignatureLoading: {
    type: Boolean,
  },
});
const emit = defineEmits(["field-changed"]);
const { isPreview, medicalLetter } = toRefs(props);
const date = ref(new Date());
const newMedication = ref("");
const medications = ref([]);
const letterBody = ref("");
const allergens = ref("");
const typeOfAllergy = ref("");

const emitFieldChange = (fieldName, value) => {
  emit("field-changed", { field: fieldName, value });
};

const addMedication = () => {
  if (newMedication.value.trim() !== "") {
    medications.value.push(newMedication.value);
    emitFieldChange("medications", medications.value);
    newMedication.value = "";
  }
};
const removeMedication = (index) => {
  medications.value.splice(index, 1);
};
</script>

<style scoped>
.font-cursive {
  font-family: "Brush Script MT", cursive;
}
img {
  max-height: 60px; /* Adjust as necessary */
}
</style>
