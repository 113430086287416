<!-- @format -->

<template>
  <div class="border p-4 rounded-md bg-white shadow-sm w-full">
    <h3 class="text-lg font-semibold">Added Signatures</h3>
    <div class="">
      <div v-if="isLoading" class="flex items-center justify-center h-52">
        <div
          class="animate-spin rounded-full h-10 w-8 border-t-4 border-teal border-b-4"
        ></div>
      </div>
      <div v-else>
        <div
          @click="emitAddSignature(-1)"
          class="border-dashed border-2 border-teal text-teal py-2 px-4 text-center cursor-pointer rounded-md mt-4 mb-4"
        >
          + Add Signature
        </div>
        <div v-if="signatureList?.length">
          <div
            v-for="(signature, index) in signatureList"
            :key="index"
            class="flex items-center justify-between mb-2"
          >
            <div
              class="flex items-center justify-between w-full"
              @click="emitAddSignature(index)"
            >
              <div
                class="flex cursor-pointer"
                :class="selectedSignatureIndex === index ? 'text-teal' : ''"
              >
                <span class="font-semibold mr-2">{{ index + 1 }}.</span>
                <span class="capitalize">{{
                  signature?.signature_name
                    ? signature?.signature_name
                    : signature?.signee
                }}</span>
              </div>

              <div class="flex items-center gap-2">
                <button
                  @click="deleteSignature(signature?.id, index)"
                  class="text-red-500 hover:text-red-700 border border-red flex items-center justify-center rounded-md"
                >
                  <span class="material-icons w-6">delete_outlined</span>
                </button>
                <button
                  @click="editSignature(index, signature?.id)"
                  class="border-teal border text-teal rounded-md flex items-center justify-center"
                >
                  <span class="material-icons w-6 text-base pl-1"
                    >edit_outlined</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { defineEmits, defineProps, toRefs, computed } from "vue";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
const emit = defineEmits([
  "add-signature-clicked",
  "edit-signature-clicked",
  "remove-signature-clicked",
]);
const props = defineProps({
  signatures: {
    type: Array,
    default: () => [],
  },
  signature: {
    type: Object,
    default: () => {},
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { signature, signatures, isLoading } = toRefs(props);
const selectedSignatureIndex = ref(null);
const userClickedAddSignature = ref(false);

const signatureList = computed(() => {
  const signList = Array.isArray(signatures.value) ? [...signatures.value] : [];
  if (signature.value && Object.keys(signature.value).length > 0) {
    signList.push(signature.value);
  }

  return signList;
});

const emitAddSignature = (index) => {
  if (index === -1) {
    selectedSignatureIndex.value = 0;
    userClickedAddSignature.value = true;
  } else {
    selectedSignatureIndex.value = index;
  }
  emit("add-signature-clicked", index);
};

watch(
  signatureList,
  () => {
    if (userClickedAddSignature.value) {
      selectedSignatureIndex.value = 0;
      userClickedAddSignature.value = false;
    }
  },
  { deep: true }
);

const deleteSignature = (id, index) => {
  if (signatures.value) {
    Swal.fire({
      width: "22%",
      padding: "1%",
      html: `
   Are you sure you want to delete this signature?
  `,
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      confirmButtonColor: "rgba(0, 164, 153, 1)",
      customClass: {
        title: "medical-letter-title",
        popup: "border-radius",
        content: "custom-text",
        icon: "icon-border",
        cancelButton: "medical-letter-no-button",
        confirmButton: "medical-letter-yes-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        signatures.value?.splice(index, 1);
        selectedSignatureIndex.value = -1;
        emit("remove-signature-clicked", id);
        Swal.fire({
          title: `Signature has been deleted Successfully!`,
          iconHtml: `<img src="${SuccessIcon}" />`,
          width: "32em",
          confirmButtonText: "OK",
          confirmButtonColor: "rgba(0, 164, 153, 1)",
          showConfirmButton: true,
          customClass: {
            title: "popup-title",
            popup: "border-radius",
            content: "custom-text",
            icon: "icon-border",
            confirmButton: "medical-letter-yes-button",
          },
        });
      }
    });
  }
};

const editSignature = (index, id) => {
  emit("edit-signature-clicked", { index, signatureId: id });
};
</script>
