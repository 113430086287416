<!-- @format -->

<template>
  <div>
    <div
      v-if="showSignaturePad"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div class="bg-white p-4 rounded-md shadow-lg">
        <div class="flex justify-end">
          <button
            class="material-icons bg-red-500 rounded-full text-white w-6"
            @click="showSignaturePad = false"
          >
            close_outlined
          </button>
        </div>
        <SignaturePad
          @close="showSignaturePad = false"
          @at-save-signature="saveSignature"
          :signee="signeeName"
          :isEdited="isEditMode"
          :key="signeeName"
        />
      </div>
    </div>

    <div class="flex gap-4" :class="isPreview ? 'justify-center' : ''">
      <div :class="isPreview ? 'w-3/6' : 'w-5/6'">
        <MedicalLetterTemplate
          :isPreview="isPreview"
          :signature="signature"
          :medicalLetter="medicalLetter?.data"
          :isSignatureLoading="signatureLoader"
          @field-changed="handleFieldChange"
          :key="signatureList"
        />
        <div class="flex mt-4" v-if="isPreview">
          <button
            class="border border-teal text-teal font-medium w-full p-3 rounded-md mr-2"
            @click="isPreview = false"
          >
            Back
          </button>
          <button
            class="bg-teal text-white w-full font-medium p-3 rounded-md"
            @click="sendCertificate"
          >
            Send Certificate
          </button>
        </div>
      </div>
      <div class="w-2/6" v-if="!isPreview">
        <AddSignature
          @add-signature-clicked="addSignature"
          @remove-signature-clicked="removeSignature"
          @select-signature="handleSelectSignature"
          @edit-signature-clicked="editSignature"
          :isLoading="signatureLoader"
          :signature="currentSignature"
          :signatures="signatureList"
        />
      </div>
    </div>
    <div class="flex mt-4" v-if="!isPreview">
      <button
        class="border border-teal text-teal font-medium w-full p-3 rounded-md mr-2"
        @click="router.go(-1)"
      >
        Back
      </button>
      <button
        class="bg-teal text-white w-full font-medium p-3 rounded-md"
        :class="isFormChanged ? '' : ' bg-opacity-60 cursor-not-allowed'"
        :disabled="!isFormChanged"
        @click="navigate(1)"
      >
        Save & Preview
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import AddSignature from "../../../components/newUi/MedicalLetters/AddSignature.vue";
import MedicalLetterTemplate from "../../../components/newUi/MedicalLetters/MedicalLetterTemplate.vue";
import SignaturePad from "@/components/newUi/atoms/SignaturePad.vue";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const isPreview = ref(false);
const showSignaturePad = ref(false);
const signature = ref("");
const router = useRouter();
const currentSignature = ref({});
const isFormChanged = ref(false);
const letterId = route.params.letterId;
const signatureLoader = ref(false);
const signeeName = ref("");
const selectedSignature = {};
const isLoading = ref(false);
const isEditMode = ref(false);
const letter = ref({
  typeOfAllergy: "",
  allergens: "",
  letterBody: "",
  medications: [],
});

const validate = (letterElements) => {
  let hasError = false;
  if (!letterElements.allergens) hasError = true;
  if (!letterElements.letterBody) hasError = true;
  if (!letterElements.typeOfAllergy) hasError = true;
  if (!signature.value) hasError = true;

  return !hasError;
};

watch([letter, signature], () => {
  isFormChanged.value = validate(letter.value);
});

const handleFieldChange = (payload) => {
  letter.value[payload.field] = payload.value;
};

const navigate = (letterId) => {
  console.log("here os the letter id ===>", letterId);
  isPreview.value = true;
};
const medicalLetter = computed(() => {
  return store.getters["PMLModule/getMedicalLetterRequest"];
});
const signatureList = computed(() => {
  return store.getters["PMLModule/getSignature"];
});
const handleSelectSignature = (index) => {
  currentSignature.value = signatureList.value[index];
};

const saveSignature = (signatureValue) => {
  signature.value = signatureValue;
  signatureLoader.value = true;
  const formdata = new FormData();
  formdata.append("signature_image", signatureValue.signatureImageFile);
  formdata.append("signature_name", signatureValue.signee);
  showSignaturePad.value = false;
  if (!isEditMode.value) {
    store
      .dispatch("PMLModule/createSignature", formdata)
      .then(() => {
        signatureLoader.value = false;
      })
      .catch(() => {
        signatureLoader.value = false;
      });
  } else {
    store
      .dispatch("PMLModule/editSignature", {
        formdata,
        signature: selectedSignature.value,
      })
      .then(() => {
        signeeName.value == "";
        isEditMode.value = false;
        signatureLoader.value = false;
      })
      .catch(() => {
        signatureLoader.value = false;
      });
  }
};

const addSignature = (index) => {
  if (index == -1) showSignaturePad.value = true;
  else {
    signature.value = signatureList.value[index];
  }
};

const removeSignature = (id) => {
  signature.value = "";
  signatureLoader.value = true;

  store
    .dispatch("PMLModule/deleteSignature", id)
    .then(() => {
      signatureLoader.value = false;
    })
    .catch(() => {
      signatureLoader.value = false;
    });
};

const editSignature = (editedSignature) => {
  selectedSignature.value = editedSignature;
  isEditMode.value = true;
  showSignaturePad.value = true;
  signeeName.value =
    signatureList.value[editedSignature?.index]?.signature_name;
};

const sendCertificate = () => {
  Swal.fire({
    width: "22%",
    padding: "1%",
    html: `
   Are you sure you want to send <b> Allergy Certificate to Philip Carder? </b>
  `,
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonText: "Yes",
    reverseButtons: true,
    confirmButtonColor: "rgba(0, 164, 153, 1)",
    customClass: {
      title: "medical-letter-title",
      popup: "border-radius",
      content: "custom-text",
      icon: "icon-border",
      cancelButton: "medical-letter-no-button",
      confirmButton: "medical-letter-yes-button",
    },
  }).then((result) => {
    if (result.isConfirmed)
      Swal.fire({
        title: `Allergy Certificate Sent to Phillip Carder via Email`,
        iconHtml: `<img src="${SuccessIcon}" />`,
        width: "32em",
        confirmButtonText: "Done",
        confirmButtonColor: "rgba(0, 164, 153, 1)",
        customClass: {
          title: "popup-title",
          popup: "border-radius",
          content: "custom-text",
          icon: "icon-border",
          confirmButton: "medical-letter-yes-button",
        },
        didClose: () => {
          // router.push({
          //   name: "hq-monitoringOfPracticeFunctionsComplianceDashboard",
          // });
        },
        showConfirmButton: true,
      });
  });
};

onMounted(() => {
  store
    .dispatch("PMLModule/fetchMedicalLetterRequest", {
      letterId: letterId,
    })
    .then(() => {
      isLoading.value = false;
    });
  signatureLoader.value = true;
  store.dispatch("PMLModule/fetchSignature").then(() => {
    signatureLoader.value = false;
  });
});
</script>
